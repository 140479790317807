import React from 'react';
import { Link } from 'gatsby';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { Layout, RandomOp, Typography } from '../../components';
import {
  MainTitle, MainPart, Figure, FigureDouble, List,
} from '../../components/opComponents';

const FractureDeScaphoide = () => (
  <Layout
    title="Fracture du scaphoïde : Diagnostic et traitement"
    description="La fracture du scaphoïde est la fracture la plus fréquente des os du carpe. Il s'agit d'une fracture difficile à détecter, pouvant passer inaperçue."
    headerStyle="white"
  >
    <div className="flex flex-col px-4 py-16 mx-auto space-y-4 max-w-screen-mdx">
      <Link to="/chirurgie-de-la-main" className="flex flex-row items-center space-x-3 uppercase text-primary group">
        <FontAwesomeIcon className="transition-all duration-300 ease-in-out group-hover:text-link" icon="long-arrow-alt-left" />
        <Typography className="group-hover:text-link" weight="semiBold" transition="medium">Retour aux opérations de la main</Typography>
      </Link>
      <div className="flex flex-col space-y-6">
        <MainTitle currentOpTitle="Fracture du scaphoïde" />
        <Typography>
          Très fréquente, la fracture du scaphoïde représente 60 % des fractures des os du carpe.
          Un traitement rapide permet d&apos;éviter une évolution vers d&apos;autres pathologies.
        </Typography>

        <MainPart title="Qu'est-ce que le scaphoïde ?">
          <Typography>
            Le scaphoïde est un os qui fait partie des huit os qui composent le carpe. Il est
            situé dans la première rangée du carpe, sur la partie externe du côté du pouce.
            (Figure 1.) Du fait de sa position et de sa vascularisation précaire, il est
            particulièrement exposé lors d&apos;un traumatisme.
          </Typography>
          <Figure
            imageName="figure1-fracturedescaphoide.jpg"
            caption="Figure 1. Anatomie des os du poignet"
            currentOpTitle="Fracture de scaphoïde"
          />
        </MainPart>

        <MainPart title="Fracture du scaphoïde : quelles sont les causes ?">
          <Typography>
            La plupart du temps, la fracture du scaphoïde survient après une chute avec
            réception sur la main, lorsque le poignet est en hyperextension.
          </Typography>
          <Typography>
            Les personnes les plus touchées sont des sujets jeunes, en moyenne 25 ans,
            souvent de sexe masculin.
          </Typography>
          <Typography>
            La fracture du scaphoïde demande une prise en charge précoce et adaptée. En
            l&apos;absence de traitement ou en cas de traitement tardif, elle peut évoluer
            vers la pseudarthrose (non consolidation). Celle-ci est responsable, en outre,
            d&apos;une destruction progressive de l&apos;articulation du poignet avec apparition
            d&apos;arthrose. (Figure 2).
          </Typography>
          <Figure
            imageName="figure2-fracturedescaphoide.jpg"
            caption="Figure 2. Destruction du poignet avec importante arthrose dans les suites d&apos;une fracture négligée du scaphoïde"
            currentOpTitle="Fracture de scaphoïde"
          />
        </MainPart>

        <MainPart title="Symptômes d&apos;une fracture du scaphoïde">
          <Typography>
            La fracture du scaphoïde a pour principal symptôme une douleur du bord externe du
            poignet, au niveau de la tabatière anatomique qui se trouve à la base du pouce.
            (Figure 3).
          </Typography>
          <Typography>
            Néanmoins, il existe des formes atypiques, avec des douleurs discrètes et passagères.
          </Typography>
          <Typography>
            Cette fracture est difficile à détecter et peut passer inaperçue.
          </Typography>
          <Figure
            maxWidth="max-w-[400px]"
            imageName="figure4-fracturedescaphoide.jpg"
            caption="Figure 3. Douleur du bord externe du poignet en regard de la tabatière anatomique"
            currentOpTitle="Fracture de scaphoïde"
          />
        </MainPart>

        <MainPart title="Comment diagnostique-t-on une fracture du scaphoïde ?">
          <Typography>
            Pour poser le diagnostic d&apos;une fracture du scaphoïde, les clichés radiographiques
            (incidence spécifique) sont proposés. (Figure 4)
          </Typography>
          <Typography>
            Ils permettent le plus souvent de faire le diagnostic avec certitude.
          </Typography>
          <Figure
            maxWidth="max-w-[250px]"
            imageName="figure5-fracturedescaphoide.jpg"
            caption="Figure 4. Fracture du scaphoïde sur un cliché radiographique standard"
            currentOpTitle="Fracture de scaphoïde"
          />
        </MainPart>

        <MainPart title="Les traitements de la fracture du scaphoïde">
          <Typography>
            La fracture du scaphoïde doit impérativement être traitée. Une prise en charge précoce
            permet d&apos;éviter la pseudarthrose (non consolidation). La pseudarthrose peut en
            effet évoluer vers une destruction de l&apos;articulation du poignet (arthrose).
          </Typography>
          <Typography>
            Il existe deux types de traitements pour la fracture du scaphoïde. Mais dans tous les
            cas, l&apos;arrêt du tabac, s&apos;il y a lieu, est indispensable pour une bonne
            consolidation.
          </Typography>
          <Typography weight="bold">
            Les traitements non chirurgicaux :
            {' '}
            <Typography variant="span">
              Le traitement non chirurgical est indiqué en cas de fracture non déplacée du
              scaphoïde. Le traitement préconisé est l&apos;immobilisation circulaire, en
              plâtre ou en résine, qui va bloquer le poignet (le pouce et le coude sont en
              général libres). Cette immobilisation va durer 6 semaines à 3 mois, selon la
              localisation du trait de fracture (figure 5).
            </Typography>
            <Typography>
              Ce traitement présente toutefois un risque de pseudarthrose en raison
              d&apos;absence de compression au niveau du foyer de la fracture.
            </Typography>
          </Typography>
          <Figure
            maxWidth="max-w-[400px]"
            imageName="figure6-fracturedescaphoide.jpg"
            caption="Figure 5. Manchette en résine pour le traitement d&apos;une fracture du scaphoïde"
            currentOpTitle="Fracture de scaphoïde"
          />
          <Typography weight="bold">
            Les traitements chirurgicaux :
            {' '}
            <Typography variant="span">
              Le traitement chirurgical, lui, permet de diminuer le risque de pseudarthrose.
            </Typography>
            <Typography>
              Dans la majorité des cas, le chirurgien va réaliser l&apos;intervention sous
              anesthésie loco-régionale (seul le bras est endormi), en ambulatoire (pas
              d&apos;hospitalisation).
            </Typography>
            <Typography>
              Deux types de chirurgies sont praticables, en fonction du type de fracture et
              du profil du patient :
            </Typography>
          </Typography>
          <Typography weight="bold">
            1. Vissage percutané en compression :
            {' '}
            <Typography variant="span">
              Cette intervention est indiquée en cas de fracture non déplacée, chez les patients
              jeunes et actifs. Le chirurgien met en place une vis en compression dans le
              scaphoïde, à travers une petite incision de moins d&apos;un centimètre dans la
              paume de la main (figure 6).
            </Typography>
          </Typography>
          <FigureDouble
            maxWidth="max-w-[300px]"
            height="h-[150px]"
            imageNameOne="figure7-fracturedescaphoide.jpg"
            imageNameTwo="figure7bis-fracturedescaphoide.jpg"
            caption="Figure 6. Vis en compression positionnée au niveau de la fracture du scaphoïde"
            currentOpTitle="Fracture de scaphoïde"
          />
          <Typography>
            Cela permet de réduire la durée de l&apos;immobilisation post-opératoire. Une attelle
            amovible est le plus souvent mise en place pour 2 à 3 semaines (figure 7).
          </Typography>
          <Figure
            imageName="figure8-fracturedescaphoide.jpg"
            caption="Figure 7. Attelle amovible de poignet"
            currentOpTitle="Fracture de scaphoïde"
          />
          <Typography weight="bold">
            2. Vissage à ciel ouvert en compression :
            {' '}
            <Typography variant="span">
              Le vissage à ciel ouvert est, quant à lui, indiqué en cas de fracture déplacée du
              scaphoïde (Figure 8). Le chirurgien va pratiquer une incision plus large au niveau
              de la paume de la main, ou du dos de la main si nécessaire. Le scaphoïde sera abordé
              afin de réaligner les fragments et de mettre en place la vis de compression. Le
              poignet est là aussi immobilisé par une attelle amovible pour une durée de 4 à 6
              semaines.
            </Typography>
          </Typography>
          <FigureDouble
            maxWidth="max-w-[400px]"
            height="h-[300px]"
            imageNameOne="figure9-fracturedescaphoide.jpg"
            imageNameTwo="figure9bis-fracturedescaphoide.jpg"
            caption="Figure 8. Fracture déplacée du scaphoïde nécessitant une chirurgie à ciel ouvert"
            currentOpTitle="Fracture de scaphoïde"
          />
        </MainPart>

        <MainPart title="Suites post opératoire en cas de chirurgie">
          <Typography>
            La suite opératoire pour une fracture du scaphoïde est l&apos;immobilisation
            du poignet. La durée varie de 2 semaines à 3 mois, selon le traitement choisi.
            Les doigts ainsi que le coude peuvent être utilisés pendant cette période
            d&apos;immobilisation. La rééducation du poignet après une opération n&apos;est
            pas systématique, mais peut être envisagée en cas de raideur persistante.
          </Typography>
        </MainPart>

        <MainPart title="Les complications de la chirurgie">
          <Typography>
            Toute intervention chirurgicale peut présenter un risque de complication
            secondaire à l&apos;intervention. On classe les complications en deux catégories.
          </Typography>
          <div>
            <Typography size="mdx" weight="bold">Complications non spécifiques</Typography>
            <Typography>
              Dans les complications non spécifiques, on peut citer :
            </Typography>
          </div>
          <List>
            <List.Item>
              <Typography>
                Une infection du site opératoire qui peut nécessiter une intervention
                de nettoyage associé à la prise d&apos;antibiotiques.
              </Typography>
            </List.Item>
            <List.Item>
              <Typography>
                Un hématome pouvant nécessiter une évacuation en cas de menace cutanée
                ou de compression nerveuse.
              </Typography>
            </List.Item>
            <List.Item>
              <Typography>
                Une algodystrophie. Son apparition est indépendante du type de chirurgie. Elle
                évolue en deux phases : la phase chaude (main gonflée, douloureuse avec
                transpiration). Puis la phase froide (raideur prédominante). L&apos;évolution est
                souvent longue, entre 12 et 18 mois et des séquelles sont possibles (douleur et/ou
                raideur des articulations des doigts et/ou poignet et/ou épaule).
              </Typography>
            </List.Item>
            <List.Item>
              <Typography>
                Les accidents liés à l&apos;anesthésie.
              </Typography>
            </List.Item>
          </List>
          <div>
            <Typography size="mdx" weight="bold">Complications spécifiques</Typography>
            <Typography>
              Ces complications concernent plus précisément le poignet. Ainsi, on retrouve :
            </Typography>
          </div>
          <List>
            <List.Item>
              <Typography>
                La pseudarthrose (non consolidation) du scaphoïde.
              </Typography>
            </List.Item>
            <List.Item>
              <Typography>
                Une gêne provoquée par le matériel pouvant motiver l&apos;ablation de la vis.
                Ce cas est rare.
              </Typography>
            </List.Item>
            <List.Item>
              <Typography>
                Une raideur et des douleurs résiduelles du poignet.
              </Typography>
            </List.Item>
          </List>
        </MainPart>

      </div>
    </div>
    <RandomOp type="mains" />
  </Layout>
);

export default FractureDeScaphoide;
